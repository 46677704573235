export const images = {
  addUserIcon: require('./addUserIcon.png'),
  background: require('./background.png'),
  bgCarbon1: require('./bgCarbon1.png'),
  bgCarbon2: require('./bgCarbon2.png'),
  bgCarbon3: require('./bgCarbon3.png'),
  bgCarbon4: require('./bgCarbon4.png'),
  bgCarbon5: require('./bgCarbon5.png'),
  bgCarbon6: require('./bgCarbon6.png'),
  calendarIcon: require('./calendarIcon.png'),
  carbonIcon: require('./carbonIcon.png'),
  checkIcon: require('./checkIcon.png'),
  checkIconGreen: require('./checkIconGreen.png'),
  checkIconRed: require('./checkIconRed.png'),
  csvIcon: require('./csvIcon.png'),
  cycleIcon: require('./cycleIcon.png'),
  dropInIcon: require('./dropInIcon.png'),
  emailSentIcon: require('./emailSentIcon.png'),
  eyeIcon: require('./eyeIcon.png'),
  loginThumbnail: require('./loginThumbnail.png'),
  logoRekoButton: require('./logoRekoButton.png'),
  logoTagline: require('./logoTagline.png'),
  keyIcon: require('./keyIcon.png'),
  jpegIcon: require('./jpegIcon.png'),
  recycleIcon: require('./recycleIcon.png'),
  requestResetThumbnail: require('./requestResetThumbnail.png'),
  resetPassSuccessIcon: require('./resetPassSuccessIcon.png'),
  resetPassThumbnail: require('./resetPassThumbnail.png'),
  saveIcon: require('./saveIcon.png'),
  searchDocumentIcon: require('./searchDocumentIcon.png'),
  taglineReko: require('./taglineReko.png'),
  taglineWhite: require('./taglineWhite.png'),
  transactionIcon: require('./transactionIcon.png'),
  trashIcon: require('./trashIcon.png'),
  truckIcon: require('./truckIcon.png'),
  userIcon: require('./userIcon.png'),
  weightIcon: require('./weightIcon.png'),
  xlsIcon: require('./xlsIcon.png'),
};
