import {
  UserDetailType,
  WasteManagementResponseType,
  WasteManagementType,
} from '../types/User';
import {
  ALL_SUB_ACCOUNT,
  BRANDBOX_TYPE,
  BUSINESS_TYPE,
  DASHBOARD_BUSINESS,
  DASHBOARD_DROP_IN,
  DASHBOARD_REBOX_BRANDBOX,
  DATE_NULL,
  DATE_OPTION,
  DATE_OPTION_MONTH,
  DATE_OPTION_WEEK,
  downloadOptionXLS,
  DROP_IN_TYPE,
  REBOX_TYPE,
} from '../config/global.config';
import html2canvas from 'html2canvas';
import {
  BusinessWeightPerPickupType,
  BusinessWeightPerTypeType,
  DropInTotalDropType,
  DropInTotalUserType,
  GetOverviewReboxBrandboxType,
  GetOverviewType,
  GetWeightPerPickupType,
  LinkType,
  NodeType,
  ReboxBrandboxTotalTransactionType,
  ReboxBrandboxTotalWasteType,
  SankeyType,
  WasteJourneyType,
} from '../types/Dashboard';
import { DateSettingsType } from '../app/modules/profile/slice/types';
import { formatDashboardPostDate, formatDMY, formatMonthYear } from './date';
import { OptionType } from '../app/components/Dropdown/floatingDropdown';
import { defaultSelectedWasteType } from '../app/modules/dashboard/slice';
import {
  BRANDBOX_TYPE_COMPILE,
  BUSINESS_TYPE_COMPILE,
  CSV_TYPE,
  DROP_IN_TYPE_COMPILE,
  REBOX_TYPE_COMPILE,
  XLSX_TYPE,
  Y_PARAM_CONVERTER_TYPE,
} from '../constants';
import { isEmptyString, replaceChar } from './check-string';
import { SubAccountType } from '../types/Account';
import { isEmptyList } from './list';

const FIRST_ARRAY: number = 0;
const MANAGEMENT_TYPE: string = 'management';
const DROP_POINT_TYPE: string = 'drop-point';

export function convertWasteManagementType(
  wasteManagement: WasteManagementType,
) {
  if (wasteManagement.type === BUSINESS_TYPE) {
    return DASHBOARD_BUSINESS;
  } else if (wasteManagement.type === DROP_IN_TYPE) {
    return DASHBOARD_DROP_IN;
  } else {
    return DASHBOARD_REBOX_BRANDBOX;
  }
}

export const wasteManagementList = (
  datas: WasteManagementResponseType[] | undefined,
) => {
  const business: WasteManagementType[] = [];
  const dropIn: WasteManagementType[] = [];
  const rebox: WasteManagementType[] = [];
  const brandbox: WasteManagementType[] = [];

  datas?.map(data => {
    const temp: WasteManagementType = {
      ...data,
      wasteManagementTypeId: [data.id],
      wasteManagementTypeName: data.name,
      dropPointId: [data.dropPointId],
      subAccounts: data.locations,
    };
    if (data.type === BUSINESS_TYPE) {
      business.push(temp);
    }
    if (data.type === DROP_IN_TYPE) {
      dropIn.push(temp);
    }
    if (data.type === REBOX_TYPE) {
      rebox.push(temp);
    }
    if (data.type === BRANDBOX_TYPE) {
      brandbox.push(temp);
    }
  });

  if (business.length > 1) {
    const comp: WasteManagementType = compileType(
      business,
      BUSINESS_TYPE,
      BUSINESS_TYPE_COMPILE,
      FIRST_ARRAY,
      MANAGEMENT_TYPE,
    );
    business.unshift(comp);
  }

  if (dropIn.length > 1) {
    const comp: WasteManagementType = compileType(
      dropIn,
      DROP_IN_TYPE,
      DROP_IN_TYPE_COMPILE,
      FIRST_ARRAY,
      DROP_POINT_TYPE,
    );
    dropIn.unshift(comp);
  }

  if (rebox.length > 1) {
    const comp: WasteManagementType = compileType(
      rebox,
      REBOX_TYPE,
      REBOX_TYPE_COMPILE,
      FIRST_ARRAY,
      DROP_POINT_TYPE,
    );
    rebox.unshift(comp);
  }

  if (brandbox.length > 1) {
    const comp: WasteManagementType = compileType(
      brandbox,
      BRANDBOX_TYPE,
      BRANDBOX_TYPE_COMPILE,
      FIRST_ARRAY,
      DROP_POINT_TYPE,
    );
    brandbox.unshift(comp);
  }

  return business.concat(dropIn, rebox, brandbox);
};

export const compileType = (
  data: WasteManagementType[],
  type: number,
  name: string,
  id: number,
  compile: string,
) => {
  const comp: WasteManagementType = {
    type: type,
    wasteManagementTypeId:
      compile === MANAGEMENT_TYPE
        ? []
        : data[FIRST_ARRAY].wasteManagementTypeId,
    dropPointId:
      compile === MANAGEMENT_TYPE ? data[FIRST_ARRAY].dropPointId : [],
    wasteManagementTypeName: name,
    subAccounts: [],
  };
  data.map(item => {
    if (compile === MANAGEMENT_TYPE) {
      comp.wasteManagementTypeId = comp.wasteManagementTypeId.concat(
        item.wasteManagementTypeId,
      );
    } else {
      comp.dropPointId = comp.dropPointId.concat(item.dropPointId);
    }
    comp.subAccounts = [...comp.subAccounts, ...item.subAccounts];
  });
  return comp;
};

export const downloadSection = async (id: string) => {
  const element = document.getElementById(id);
  const link = document.createElement('a');
  let canvas;
  if (element) {
    canvas = await html2canvas(element);
  }

  link.href = canvas.toDataURL('image/jpg');
  link.download = id + '.jpg';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const convertGetDataParameter = (
  dateSettings: DateSettingsType,
  selectedWasteManagementType: WasteManagementType,
  selectedSubAccount: string,
  userDetail: UserDetailType,
  wasteType?: OptionType[],
  selectedWasteType?: OptionType,
  fileType?: string,
  dropPointId?: number[],
) => {
  const data: GetWeightPerPickupType = {
    startDate:
      dateSettings.type === 'all-time'
        ? DATE_NULL
        : formatDashboardPostDate(dateSettings.startDate),
    endDate: formatDashboardPostDate(dateSettings.endDate),
    typeDate: dateSettings.type,
    wasteManagementTypeId: selectedWasteManagementType.wasteManagementTypeId,
    subAccounts: [],
  };

  if (
    selectedSubAccount === ALL_SUB_ACCOUNT &&
    !isEmptyList(selectedWasteManagementType?.subAccounts)
  )
    selectedWasteManagementType.subAccounts.map(item => {
      data.subAccounts.push(item.name);
    });
  else if (
    selectedSubAccount !== ALL_SUB_ACCOUNT &&
    !isEmptyList(selectedWasteManagementType?.subAccounts)
  )
    data.subAccounts.push(selectedSubAccount);
  else data.subAccounts = [];

  if (wasteType && selectedWasteType) {
    data.wasteType = [];
    if (selectedWasteType === defaultSelectedWasteType)
      wasteType.map(item => {
        if (item !== defaultSelectedWasteType) {
          data.wasteType?.push(item.key);
        }
      });
    else data.wasteType?.push(selectedWasteType.key);
  }

  if (fileType) data.fileType = fileType;

  if (dropPointId) data.dropPointId = dropPointId;

  return data;
};

export const percentage = (value: number, data: number[]) => {
  let sum = 0;
  data.map(item => {
    sum = sum + item;
  });
  return (value / sum) * 100;
};

export const xParamConverter = (
  data:
    | BusinessWeightPerPickupType[]
    | DropInTotalDropType[]
    | DropInTotalUserType[]
    | ReboxBrandboxTotalTransactionType[]
    | ReboxBrandboxTotalWasteType[],
  dateSettings: DateSettingsType,
) => {
  const xParam: string[] = [];
  if (data) {
    data.map(item => {
      if (dateSettings.type === DATE_OPTION[DATE_OPTION_MONTH].key) {
        xParam.push(formatMonthYear(item.xParam));
      } else if (dateSettings.type === DATE_OPTION[DATE_OPTION_WEEK].key) {
        xParam.push('Week ' + item.xParam);
      } else {
        xParam.push(formatDMY(item.xParam));
      }
    });
  }
  return xParam;
};

export const yParamConverter = (
  data:
    | BusinessWeightPerPickupType[]
    | DropInTotalDropType[]
    | DropInTotalUserType[]
    | ReboxBrandboxTotalTransactionType[]
    | ReboxBrandboxTotalWasteType[],
  type: Y_PARAM_CONVERTER_TYPE,
) => {
  const yParam: number[] = [];
  if (data) {
    data.map(item => {
      if (type === Y_PARAM_CONVERTER_TYPE.Weight) yParam.push(item.totalWeight);
      if (type === Y_PARAM_CONVERTER_TYPE.Drop) yParam.push(item.totalDropin);
      if (type === Y_PARAM_CONVERTER_TYPE.User) yParam.push(item.totalUser);
      if (type === Y_PARAM_CONVERTER_TYPE.Transaction)
        yParam.push(item.totalTransaction);
      if (type === Y_PARAM_CONVERTER_TYPE.Waste) yParam.push(item.totalWaste);
    });
  }
  return yParam;
};

export const chartDataConverter = (data: BusinessWeightPerTypeType[]) => {
  const chartData: number[] = [];
  if (data) {
    data.map(item => {
      chartData.push(item.weight);
    });
  }
  return chartData;
};

export const chartLabelConverter = (data: BusinessWeightPerTypeType[]) => {
  const chartLabel: string[] = [];
  if (data) {
    data.map(item => {
      chartLabel.push(item.type);
    });
  }
  return chartLabel;
};

export const chartColorConverter = (data: BusinessWeightPerTypeType[]) => {
  const chartColor: string[] = [];
  if (data) {
    data.map(item => {
      chartColor.push('#' + replaceChar(item.color, '#', ''));
    });
  }
  return chartColor;
};

export const convertDataOverview = (
  dateSettings: DateSettingsType,
  selectedWasteManagementType: WasteManagementType,
  selectedSubAccount: string,
  userDetail: UserDetailType,
  // drop point id intentionally made into separate
  // param form selected waste management type to make function reusable
  dropPointId?: number[],
) => {
  const data: GetOverviewType = {
    startDate:
      dateSettings.type === 'all-time'
        ? DATE_NULL
        : formatDashboardPostDate(dateSettings.startDate),
    endDate: formatDashboardPostDate(dateSettings.endDate),
    typeDate: dateSettings.type,
    wasteManagementTypeId: selectedWasteManagementType.wasteManagementTypeId,
    subAccounts: [],
  };

  if (
    selectedSubAccount === ALL_SUB_ACCOUNT &&
    !isEmptyList(selectedWasteManagementType?.subAccounts)
  )
    selectedWasteManagementType.subAccounts.map(item => {
      data.subAccounts.push(item.name);
    });
  else if (
    selectedSubAccount !== ALL_SUB_ACCOUNT &&
    !isEmptyList(selectedWasteManagementType?.subAccounts)
  )
    data.subAccounts.push(selectedSubAccount);
  else data.subAccounts = [];

  if (dropPointId) data.dropPointId = dropPointId;

  return data;
};

export const convertDataOverviewReboxBrandbox = (
  dateSettings: DateSettingsType,
  selectedWasteManagementType: WasteManagementType,
  wasteType?: OptionType[],
  selectedWasteType?: OptionType,
) => {
  const data: GetOverviewReboxBrandboxType = {
    startDate:
      dateSettings.type === 'all-time'
        ? DATE_NULL
        : formatDashboardPostDate(dateSettings.startDate),
    endDate: formatDashboardPostDate(dateSettings.endDate),
    typeDate: dateSettings.type,
    wasteManagementTypeId:
      selectedWasteManagementType.wasteManagementTypeId[FIRST_ARRAY],
  };

  if (selectedWasteManagementType?.dropPointId) {
    data.dropPointId = selectedWasteManagementType?.dropPointId;
  }

  if (wasteType && selectedWasteType) {
    data.wasteType = [];
    if (selectedWasteType === defaultSelectedWasteType) {
      wasteType.map(item => {
        if (item !== defaultSelectedWasteType) {
          data.wasteType?.push(item.key);
        }
      });
    } else {
      data.wasteType?.push(selectedWasteType.key);
    }
  }

  return data;
};

export const fileType = (data: OptionType) => {
  return data.key === downloadOptionXLS.key ? XLSX_TYPE : CSV_TYPE;
};

const removeString = (data: string, remove: string) => {
  const indexOfRemove = data.indexOf(remove);
  if (indexOfRemove !== -1) {
    return data.substring(0, indexOfRemove);
  } else {
    return data;
  }
};

export const sankeyDataConverter = (data: WasteJourneyType) => {
  const node: NodeType[] = [];
  data.sourceData.map(item => {
    node.push({
      name: item.from,
      color: '',
    });
    node.push({
      name: item.to,
      color: '',
    });
  });
  const mapped = node.map(obj => obj.name);
  const filtered = mapped.filter(
    (name, index) => mapped.indexOf(name) === index,
  );
  const newNode: NodeType[] = [];
  filtered.map(item => {
    const itemColor: string = data.sourceColor[
      data.sourceColor.findIndex(obj => obj.name === removeString(item, ' - '))
    ]?.color.replace('#', '');
    newNode.push({
      name: item,
      color: '#' + itemColor, // Make sure there is no '#' in the hex code,
      // and added it manually from frontend
    });
  });

  const link: LinkType[] = [];
  data.sourceData.map(item => {
    link.push({
      source: filtered.findIndex(obj => obj === item.from),
      target: filtered.findIndex(obj => obj === item.to),
      value: item.weight,
    });
  });
  const result: SankeyType = {
    links: link,
    nodes: newNode,
  };
  return result;
};

export const downloadFile = (url: string, name: string, type: string) => {
  if (!isEmptyString(url)) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name + '.' + type);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

export const blobToUrl = (blob: string, type: string) => {
  return URL.createObjectURL(
    new Blob([blob], {
      type: type,
    }),
  );
};

export const excelFilename = (name: string) => {
  return name + formatDMY(new Date());
};

export const arrayToString = (data: number[]) => {
  return data.join(',');
};

// This helper only used temporary, because report only available for user with re-pickup business for now
export const reportProgram = (
  isReport: boolean,
  data: WasteManagementType[],
) => {
  if (isReport) {
    return data.filter(item => item.type === BUSINESS_TYPE);
  } else {
    return data;
  }
};

// This helper only used temporary, because report only available for user with re-pickup business for now
export const isShowReport = (data: WasteManagementType[]) => {
  const filtered: WasteManagementType[] = data.filter(
    item => item.type === BUSINESS_TYPE,
  );
  return filtered.length > 0;
};

export const alias = (
  reportSubAccount: string,
  subAccounts: SubAccountType[],
) => {
  if (!isEmptyString(reportSubAccount)) {
    const filter = subAccounts.filter(item => item.name === reportSubAccount);
    if (filter.length > 0) return filter[0].alias;
    else return '';
  } else return '';
};
