// Use this import if you want to use "env.js" file
// const { API_URL } = require("../../config/env")
// Or just specify it directly like this:

const API_URL = process.env.REACT_APP_BASE_URL || '';
const UCO_URL = process.env.REACT_APP_UCO_URL || '';

/**
 * The options used to configure the API.
 */
export interface ApiConfig {
  /**
   * The URL of the api.
   */
  url: string;

  /**
   * Milliseconds before we timeout the request.
   */
  timeout: number;
}

/**
 * The default configuration for the app.
 */
const API_TIMEOUT: number = Number(process.env.REACT_APP_API_TIMEOUT);
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_URL,
  timeout: API_TIMEOUT,
};

export const UCO_API_CONFIG: ApiConfig = {
  url: UCO_URL,
  timeout: API_TIMEOUT,
};
