import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { dashboardSaga } from './saga';
import { DashboardState } from './types';
import {
  BusinessCarbonFootprintType,
  BusinessOverviewType,
  DropInOverviewType,
  ReboxBrandboxOverviewType,
  WasteJourneyType,
} from '../../../../types/Dashboard';
import { PayloadAction } from '@reduxjs/toolkit';
import { OptionType } from '../../../components/Dropdown/floatingDropdown';

const defaultBusinessOverview: BusinessOverviewType = {
  totalWeight: 0,
  averageWeight: 0,
  recoveryRate: 0,
  totalPickup: 0,
  totalCarbonSaving: 0,
};

const defaultBusinessCarbonFootprint: BusinessCarbonFootprintType = {
  coalBurned: 0,
  totalCarbonSaving: 0,
  gasoline: 0,
  trashBag: 0,
  treeSeed: 0,
  distanceDriven: 0,
};

const defaultReboxBrandbox: ReboxBrandboxOverviewType = {
  averageWastePerDrop: 0,
  totalUser: 0,
  averageUser: 0,
  totalWasteDrop: 0,
  totalTransaction: 0,
};

const defaultDropInOverview: DropInOverviewType = {
  totalWeight: 0,
  totalWasteDrop: 0,
  totalUser: 0,
  averageUser: 0,
  recoveryRate: 0,
  totalCarbonSaving: 0,
  averageDropin: 0,
  averageWeightPerDropin: 0,
};

const defaultWasteJourney: WasteJourneyType = {
  sourceColor: [],
  sourceData: [],
};

export const defaultSelectedWasteType: OptionType = {
  optionText: 'All type of waste',
  key: 'all',
};

export const initialState: DashboardState = {
  wasteType: [],
  selectedWasteType: defaultSelectedWasteType,
  businessOverview: defaultBusinessOverview,
  businessWeightPerPickup: [],
  businessWeightPerType: [],
  fileType: '',
  businessExcelWeightPerPickup: '',
  businessExcelWeightPerType: '',
  businessExcelWasteJourney: '',
  businessCarbonFootprint: defaultBusinessCarbonFootprint,
  reboxBrandboxOverview: defaultReboxBrandbox,
  reboxBrandboxTotalWaste: [],
  reboxBrandboxTotalTransaction: [],
  reboxBrandboxTotalUser: [],
  reboxBrandboxWeightPerType: [],
  reboxBrandboxTotalWasteExcel: '',
  reboxBrandboxWeightPerTypeExcel: '',
  reboxBrandboxTotalTransactionExcel: '',
  reboxBrandboxTotalUserExcel: '',
  dropInOverview: defaultDropInOverview,
  dropInWeight: [],
  dropInTotalDrop: [],
  dropInTotalUser: [],
  dropInWeightPerType: [],
  businessWasteJourney: defaultWasteJourney,
  dropInCarbonFootprint: defaultBusinessCarbonFootprint,
  dropInWasteJourney: defaultWasteJourney,
  dropInWeightExcel: '',
  dropInWasteExcel: '',
  dropInUserExcel: '',
  dropInWeightPerTypeExcel: '',
  dropInWasteJourneyExcel: '',
  loading: false,
  errors: null,
  message: '',
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    loadWasteType(state) {
      state.loading = true;
      state.errors = null;
      state.wasteType = [];
    },
    wasteTypeLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.wasteType = action.payload;
    },
    wasteTypeError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.wasteType = [];
    },

    setSelectedWasteType(state, action: PayloadAction<any>) {
      state.selectedWasteType = action.payload;
    },

    // Business
    loadBusinessOverview(state) {
      state.loading = true;
      state.errors = null;
      state.businessOverview = defaultBusinessOverview;
    },
    businessOverviewLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.businessOverview = action.payload.data;
    },
    businessOverviewError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.businessOverview = defaultBusinessOverview;
    },

    loadBusinessWeightPerPickup(state) {
      state.loading = true;
      state.errors = null;
      state.businessWeightPerPickup = [];
    },
    businessWeightPerPickupLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.businessWeightPerPickup = action.payload.data;
    },
    businessWeightPerPickupError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.businessWeightPerPickup = [];
    },

    loadBusinessWeightPerType(state) {
      state.loading = true;
      state.errors = null;
      state.businessWeightPerType = [];
    },
    businessWeightPerTypeLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.businessWeightPerType = action.payload.data;
    },
    businessWeightPerTypeError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.businessWeightPerType = [];
    },

    setFileType(state, action: PayloadAction<string>) {
      state.fileType = action.payload;
    },

    loadBusinessExcelWeightPerPickup(state) {
      state.loading = true;
      state.errors = null;
      state.businessExcelWeightPerPickup = '';
    },
    businessExcelWeightPerPickupLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.businessExcelWeightPerPickup = action.payload;
    },
    businessExcelWeightPerPickupError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.businessExcelWeightPerPickup = '';
    },
    resetBusinessExcelWeightPerPickup(state) {
      state.businessExcelWeightPerPickup = '';
    },

    loadBusinessExcelWeightPerType(state) {
      state.loading = true;
      state.errors = null;
      state.businessExcelWeightPerType = '';
    },
    businessExcelWeightPerTypeLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.businessExcelWeightPerType = action.payload;
    },
    businessExcelWeightPerTypeError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.businessExcelWeightPerType = '';
    },
    resetBusinessExcelWeightPerType(state) {
      state.businessExcelWeightPerType = '';
    },

    loadBusinessExcelWasteJourney(state) {
      state.loading = true;
      state.errors = null;
      state.businessExcelWasteJourney = '';
    },
    businessExcelWasteJourneyLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.businessExcelWasteJourney = action.payload;
    },
    businessExcelWasteJourneyError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.businessExcelWasteJourney = '';
    },
    resetBusinessExcelWasteJourney(state) {
      state.businessExcelWasteJourney = '';
    },

    loadBusinessCarbonFootprint(state) {
      state.loading = true;
      state.errors = null;
      state.businessCarbonFootprint = defaultBusinessCarbonFootprint;
    },
    businessCarbonFootprintLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.businessCarbonFootprint = action.payload.data;
    },
    businessCarbonFootprintError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.businessCarbonFootprint = defaultBusinessCarbonFootprint;
    },

    loadBusinessWasteJourney(state) {
      state.loading = true;
      state.errors = null;
      state.businessWasteJourney = defaultWasteJourney;
    },
    businessWasteJourneyLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.businessWasteJourney = action.payload.data;
    },
    businessWasteJourneyError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.businessWasteJourney = defaultWasteJourney;
    },

    // Brandbox-Rebox
    loadReboxBrandboxOverview(state) {
      state.loading = true;
      state.errors = null;
      state.reboxBrandboxOverview = defaultReboxBrandbox;
    },
    reboxBrandboxOverviewLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.reboxBrandboxOverview = action.payload.data;
    },
    reboxBrandboxOverviewError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.reboxBrandboxOverview = defaultReboxBrandbox;
    },

    loadReboxBrandboxTotalWaste(state) {
      state.loading = true;
      state.errors = null;
      state.reboxBrandboxTotalWaste = [];
    },
    reboxBrandboxTotalWasteLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.reboxBrandboxTotalWaste = action.payload.data;
    },
    reboxBrandboxTotalWasteError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.reboxBrandboxTotalWaste = [];
    },

    loadReboxBrandboxTotalTransaction(state) {
      state.loading = true;
      state.errors = null;
      state.reboxBrandboxTotalTransaction = [];
    },
    reboxBrandboxTotalTransactionLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.reboxBrandboxTotalTransaction = action.payload.data;
    },
    reboxBrandboxTotalTransactionError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.reboxBrandboxTotalTransaction = [];
    },

    loadReboxBrandboxWeightPerType(state) {
      state.loading = true;
      state.errors = null;
      state.reboxBrandboxWeightPerType = [];
    },
    reboxBrandboxWeightPerTypeLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.reboxBrandboxWeightPerType = action.payload.data;
    },
    reboxBrandboxWeightPerTypeError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.reboxBrandboxWeightPerType = [];
    },

    loadReboxBrandboxTotalUser(state) {
      state.loading = true;
      state.errors = null;
      state.reboxBrandboxTotalUser = [];
    },
    reboxBrandboxTotalUserLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.reboxBrandboxTotalUser = action.payload.data;
    },
    reboxBrandboxTotalUserError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.reboxBrandboxTotalUser = [];
    },

    loadReboxBrandboxTotalWasteExcel(state) {
      state.loading = true;
      state.errors = null;
      state.reboxBrandboxTotalWasteExcel = '';
    },
    reboxBrandboxTotalWasteExcelLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.reboxBrandboxTotalWasteExcel = action.payload;
    },
    reboxBrandboxTotalWasteExcelError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.reboxBrandboxTotalWasteExcel = '';
    },
    resetReboxBrandboxTotalWasteExcel(state) {
      state.reboxBrandboxTotalWasteExcel = '';
    },

    loadReboxBrandboxWeightPerTypeExcel(state) {
      state.loading = true;
      state.errors = null;
      state.reboxBrandboxWeightPerTypeExcel = '';
    },
    reboxBrandboxWeightPerTypeExcelLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.reboxBrandboxWeightPerTypeExcel = action.payload;
    },
    reboxBrandboxWeightPerTypeExcelError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.reboxBrandboxWeightPerTypeExcel = '';
    },
    resetReboxBrandboxWeightPerTypeExcel(state) {
      state.reboxBrandboxWeightPerTypeExcel = '';
    },

    loadReboxBrandboxTotalTransactionExcel(state) {
      state.loading = true;
      state.errors = null;
      state.reboxBrandboxTotalTransactionExcel = '';
    },
    reboxBrandboxTotalTransactionExcelLoaded(
      state,
      action: PayloadAction<any>,
    ) {
      state.loading = false;
      state.errors = null;
      state.reboxBrandboxTotalTransactionExcel = action.payload;
    },
    reboxBrandboxTotalTransactionExcelError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.reboxBrandboxTotalTransactionExcel = '';
    },
    resetReboxBrandboxTotalTransactionExcel(state) {
      state.reboxBrandboxTotalTransactionExcel = '';
    },

    loadReboxBrandboxTotalUserExcel(state) {
      state.loading = true;
      state.errors = null;
      state.reboxBrandboxTotalUserExcel = '';
    },
    reboxBrandboxTotalUserExcelLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.reboxBrandboxTotalUserExcel = action.payload;
    },
    reboxBrandboxTotalUserExcelError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.reboxBrandboxTotalUserExcel = '';
    },
    resetReboxBrandboxTotalUserExcel(state) {
      state.reboxBrandboxTotalUserExcel = '';
    },

    // Drop-in
    loadDropInOverview(state) {
      state.loading = true;
      state.errors = null;
      state.dropInOverview = defaultDropInOverview;
    },
    dropInOverviewLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.dropInOverview = action.payload.data;
    },
    dropInOverviewError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.dropInOverview = defaultDropInOverview;
    },

    loadDropInWeight(state) {
      state.loading = true;
      state.errors = null;
      state.dropInWeight = [];
    },
    dropInWeightLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.dropInWeight = action.payload.data;
    },
    dropInWeightError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.dropInWeight = [];
    },

    loadDropInTotalDrop(state) {
      state.loading = true;
      state.errors = null;
      state.dropInTotalDrop = [];
    },
    dropInTotalDropLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.dropInTotalDrop = action.payload.data;
    },
    dropInTotalDropError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.dropInTotalDrop = [];
    },

    loadDropInTotalUser(state) {
      state.loading = true;
      state.errors = null;
      state.dropInTotalUser = [];
    },
    dropInTotalUserLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.dropInTotalUser = action.payload.data;
    },
    dropInTotalUserError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.dropInTotalUser = [];
    },

    loadDropInCarbonFootprint(state) {
      state.loading = true;
      state.errors = null;
      state.dropInCarbonFootprint = defaultBusinessCarbonFootprint;
    },
    dropInCarbonFootprintLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.dropInCarbonFootprint = action.payload.data;
    },
    dropInCarbonFootprintError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.dropInCarbonFootprint = defaultBusinessCarbonFootprint;
    },

    loadDropInWeightPerType(state) {
      state.loading = true;
      state.errors = null;
      state.dropInWeightPerType = [];
    },
    dropInWeightPerTypeLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.dropInWeightPerType = action.payload.data;
    },
    dropInWeightPerTypeError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.dropInWeightPerType = [];
    },

    loadDropInWasteJourney(state) {
      state.loading = true;
      state.errors = null;
      state.dropInWasteJourney = defaultWasteJourney;
    },
    dropInWasteJourneyLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.dropInWasteJourney = action.payload.data;
    },
    dropInWasteJourneyError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.dropInWasteJourney = defaultWasteJourney;
    },

    loadDropInWeightExcel(state) {
      state.loading = true;
      state.errors = null;
      state.dropInWeightExcel = '';
    },
    dropInWeightExcelLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.dropInWeightExcel = action.payload;
    },
    dropInWeightExcelError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.dropInWeightExcel = '';
    },
    resetDropInWeightExcel(state) {
      state.dropInWeightExcel = '';
    },

    loadDropInWasteExcel(state) {
      state.loading = true;
      state.errors = null;
      state.dropInWasteExcel = '';
    },
    dropInWasteExcelLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.dropInWasteExcel = action.payload;
    },
    dropInWasteExcelError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.dropInWasteExcel = '';
    },
    resetDropInWasteExcel(state) {
      state.dropInWasteExcel = '';
    },

    loadDropInUserExcel(state) {
      state.loading = true;
      state.errors = null;
      state.dropInUserExcel = '';
    },
    dropInUserExcelLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.dropInUserExcel = action.payload;
    },
    dropInUserExcelError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.dropInUserExcel = '';
    },
    resetDropInUserExcel(state) {
      state.dropInUserExcel = '';
    },

    loadDropInWeightPerTypeExcel(state) {
      state.loading = true;
      state.errors = null;
      state.dropInWeightPerTypeExcel = '';
    },
    dropInWeightPerTypeExcelLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.dropInWeightPerTypeExcel = action.payload;
    },
    dropInWeightPerTypeExcelError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.dropInWeightPerTypeExcel = '';
    },
    resetDropInWeightPerTypeExcel(state) {
      state.dropInWeightPerTypeExcel = '';
    },

    loadDropInWasteJourneyExcel(state) {
      state.loading = true;
      state.errors = null;
      state.dropInWasteJourneyExcel = '';
    },
    dropInWasteJourneyExcelLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = null;
      state.dropInWasteJourneyExcel = action.payload;
    },
    dropInWasteJourneyExcelError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload.error;
      state.dropInWasteJourneyExcel = '';
    },
    resetDropInWasteJourneyExcel(state) {
      state.dropInWasteJourneyExcel = '';
    },

    resetDashboardData(state) {
      state.businessWeightPerPickup = [];
      state.businessWeightPerType = [];
      state.businessWasteJourney = defaultWasteJourney;
      state.businessCarbonFootprint = defaultBusinessCarbonFootprint;
      state.reboxBrandboxTotalWaste = [];
      state.reboxBrandboxTotalUser = [];
      state.reboxBrandboxTotalTransaction = [];
      state.reboxBrandboxWeightPerType = [];
      state.dropInTotalDrop = [];
      state.dropInTotalUser = [];
      state.dropInWeight = [];
      state.dropInWeightPerType = [];
      state.dropInCarbonFootprint = defaultBusinessCarbonFootprint;
      state.dropInWasteJourney = defaultWasteJourney;
    },
  },
});

export const { actions: dashboardActions } = slice;

export const useDashboardSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: dashboardSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAuthSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
