import { logger } from '../../../../utils/logger';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectApi } from '../../../../api/slice/selectors';
import { defaultUserDetail } from '../../profile/slice';
import {
  dashboardActions as actions,
  defaultSelectedWasteType,
} from '../../dashboard/slice';
import { DateSettingsType } from '../../profile/slice/types';
import {
  selectDateSettings,
  selectSelectedSubAccount,
  selectWasteManagementSelected,
} from '../../profile/slice/selectors';
import { UserDetailType, WasteManagementType } from '../../../../types/User';
import {
  GetOverviewReboxBrandboxType,
  GetOverviewType,
} from '../../../../types/Dashboard';
import {
  ApiMethod,
  ApiResponseKind,
  BUSINESS_CARBON_FOOTPRINT,
  BUSINESS_EXCEL_WASTE_JOURNEY,
  BUSINESS_EXCEL_WEIGHT_PER_PICK_UP,
  BUSINESS_EXCEL_WEIGHT_PER_TYPE,
  BUSINESS_OVERVIEW,
  BUSINESS_WASTE_JOURNEY,
  BUSINESS_WEIGHT_PER_TYPE,
  DROP_IN_CARBON_FOOTPRINT,
  DROP_IN_OVERVIEW,
  DROP_IN_TOTAL_DROP,
  DROP_IN_TOTAL_USER,
  DROP_IN_USER_EXCEL,
  DROP_IN_WASTE_EXCEL,
  DROP_IN_WASTE_JOURNEY,
  DROP_IN_WASTE_JOURNEY_EXCEL,
  DROP_IN_WEIGHT,
  DROP_IN_WEIGHT_EXCEL,
  DROP_IN_WEIGHT_PER_TYPE,
  DROP_IN_WEIGHT_PER_TYPE_EXCEL,
  REBOX_BRANDBOX_OVERVIEW,
  REBOX_BRANDBOX_TOTAL_TRANSACTION,
  REBOX_BRANDBOX_TOTAL_TRANSACTION_EXCEL,
  REBOX_BRANDBOX_TOTAL_USER,
  REBOX_BRANDBOX_TOTAL_USER_EXCEL,
  REBOX_BRANDBOX_TOTAL_WASTE,
  REBOX_BRANDBOX_TOTAL_WASTE_EXCEL,
  REBOX_BRANDBOX_WEIGHT_PER_TYPE,
  REBOX_BRANDBOX_WEIGHT_PER_TYPE_EXCEL,
  ResponseType,
  USER_DETAIL_KEY,
  WASTE_TYPE,
  WEIGHT_PER_PICKUP,
} from '../../../../api/api.types';
import { OptionType } from '../../../components/Dropdown/floatingDropdown';
import {
  selectFileType,
  selectSelectedWasteType,
  selectWasteType,
} from './selectors';
import {
  arrayToString,
  blobToUrl,
  convertDataOverview,
  convertDataOverviewReboxBrandbox,
  convertGetDataParameter,
} from '../../../../utils/dashboard-helper';

/**
 * Logger
 */
const log = logger().child({ module: 'DashboardSaga' });

function* getWasteType() {
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const api = yield select(selectApi);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.GET,
      WASTE_TYPE,
      false,
      undefined,
      undefined,
      {
        wasteManagementTypeId: arrayToString(
          selectedWasteManagementType.wasteManagementTypeId,
        ),
      },
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data.data;
      const wasteType: OptionType[] = [];
      wasteType.push(defaultSelectedWasteType);
      data.map(item => {
        wasteType.push({
          optionText: item?.name,
          key: item?.name,
        });
      });
      yield put(actions.wasteTypeLoaded(wasteType));
    }
  } catch (err: any) {
    yield put(actions.wasteTypeError(err));
  }
}

function* getDashboardBusinessOverview() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;

  const data: GetOverviewType = convertDataOverview(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
  );

  const api = yield select(selectApi);

  log.debug('get dashboard business overview with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      BUSINESS_OVERVIEW,
      false,
      undefined,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      yield put(actions.businessOverviewLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.businessOverviewError(err));
  }
}

function* getBusinessWeightPerPickup() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const wasteType: OptionType[] = yield select(selectWasteType);
  const selectedWasteType: OptionType = yield select(selectSelectedWasteType);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;

  const data = convertGetDataParameter(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
    wasteType,
    selectedWasteType,
  );

  const api = yield select(selectApi);

  log.debug('get dashboard business weight per pickup with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      WEIGHT_PER_PICKUP,
      false,
      undefined,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      yield put(actions.businessWeightPerPickupLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.businessWeightPerPickupError(err));
  }
}

function* getBusinessWeightPerType() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;

  const data = convertGetDataParameter(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
  );

  const api = yield select(selectApi);

  log.debug('get dashboard business weight per type with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      BUSINESS_WEIGHT_PER_TYPE,
      false,
      undefined,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      yield put(actions.businessWeightPerTypeLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.businessWeightPerTypeError(err));
  }
}

function* getBusinessExcelWeightPerPickup() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const wasteType: OptionType[] = yield select(selectWasteType);
  const selectedWasteType: OptionType = yield select(selectSelectedWasteType);
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;
  const fileType: string = yield select(selectFileType);

  const data = convertGetDataParameter(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
    wasteType,
    selectedWasteType,
    fileType,
  );

  const api = yield select(selectApi);

  log.debug('get dashboard business excel weight per pickup with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      BUSINESS_EXCEL_WEIGHT_PER_PICK_UP,
      false,
      ResponseType.BLOB,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const url = blobToUrl(response.data, '');
      yield put(actions.businessExcelWeightPerPickupLoaded(url));
    }
  } catch (err: any) {
    yield put(actions.businessExcelWeightPerPickupError(err));
  }
}

function* getBusinessExcelWeightPerType() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;
  const fileType: string = yield select(selectFileType);

  const data = convertGetDataParameter(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
    undefined,
    undefined,
    fileType,
  );

  const api = yield select(selectApi);

  log.debug('get dashboard business excel weight per type with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      BUSINESS_EXCEL_WEIGHT_PER_TYPE,
      false,
      ResponseType.BLOB,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const url = blobToUrl(response.data, '');
      yield put(actions.businessExcelWeightPerTypeLoaded(url));
    }
  } catch (err: any) {
    yield put(actions.businessExcelWeightPerTypeError(err));
  }
}

function* getBusinessExcelWasteJourney() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;
  const fileType: string = yield select(selectFileType);

  const data = convertGetDataParameter(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
    undefined,
    undefined,
    fileType,
  );

  const api = yield select(selectApi);

  log.debug('get dashboard business excel waste journey: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      BUSINESS_EXCEL_WASTE_JOURNEY,
      false,
      ResponseType.BLOB,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const url = blobToUrl(response.data, '');
      yield put(actions.businessExcelWasteJourneyLoaded(url));
    }
  } catch (err: any) {
    yield put(actions.businessExcelWasteJourneyError(err));
  }
}

function* getBusinessCarbonFootprint() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;

  const data = convertGetDataParameter(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
  );

  const api = yield select(selectApi);

  log.debug('get dashboard business carbon saving with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      BUSINESS_CARBON_FOOTPRINT,
      false,
      undefined,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      yield put(actions.businessCarbonFootprintLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.businessCarbonFootprintError(err));
  }
}

function* getBusinessWasteJourney() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;

  const data = convertGetDataParameter(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
  );

  const api = yield select(selectApi);

  log.debug('get dashboard business waste journey with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      BUSINESS_WASTE_JOURNEY,
      false,
      undefined,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      yield put(actions.businessWasteJourneyLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.businessWasteJourneyError(err));
  }
}

// Rebox-Brandbox
function* getDashboardReboxBrandboxOverview() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );

  const data: GetOverviewReboxBrandboxType = convertDataOverviewReboxBrandbox(
    dateSettings,
    selectedWasteManagementType,
  );

  const api = yield select(selectApi);

  log.debug('get dashboard rebox brandbox overview with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      REBOX_BRANDBOX_OVERVIEW,
      false,
      undefined,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      yield put(actions.reboxBrandboxOverviewLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.reboxBrandboxOverviewError(err));
  }
}

function* getReboxBrandboxTotalWaste() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const wasteType: OptionType[] = yield select(selectWasteType);
  const selectedWasteType: OptionType = yield select(selectSelectedWasteType);

  const data: GetOverviewReboxBrandboxType = convertDataOverviewReboxBrandbox(
    dateSettings,
    selectedWasteManagementType,
    wasteType,
    selectedWasteType,
  );

  const api = yield select(selectApi);

  log.debug('get rebox brandbox total weight with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      REBOX_BRANDBOX_TOTAL_WASTE,
      false,
      undefined,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      yield put(actions.reboxBrandboxTotalWasteLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.reboxBrandboxTotalWasteError(err));
  }
}

function* getDashboardReboxBrandboxTotalTransaction() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );

  const data: GetOverviewReboxBrandboxType = convertDataOverviewReboxBrandbox(
    dateSettings,
    selectedWasteManagementType,
  );

  const api = yield select(selectApi);

  log.debug('get dashboard rebox brandbox total transaction with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      REBOX_BRANDBOX_TOTAL_TRANSACTION,
      false,
      undefined,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      yield put(actions.reboxBrandboxTotalTransactionLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.reboxBrandboxTotalTransactionError(err));
  }
}

function* getDashboardReboxBrandboxTotalUser() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );

  const data: GetOverviewReboxBrandboxType = convertDataOverviewReboxBrandbox(
    dateSettings,
    selectedWasteManagementType,
  );

  const api = yield select(selectApi);

  log.debug('get dashboard rebox brandbox total user with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      REBOX_BRANDBOX_TOTAL_USER,
      false,
      undefined,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      yield put(actions.reboxBrandboxTotalUserLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.reboxBrandboxTotalUserError(err));
  }
}

function* getDashboardReboxBrandboxWeightPerType() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );

  const data: GetOverviewReboxBrandboxType = convertDataOverviewReboxBrandbox(
    dateSettings,
    selectedWasteManagementType,
  );

  const api = yield select(selectApi);

  log.debug('get dashboard rebox brandbox weight per type: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      REBOX_BRANDBOX_WEIGHT_PER_TYPE,
      false,
      undefined,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      yield put(actions.reboxBrandboxWeightPerTypeLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.reboxBrandboxWeightPerTypeError(err));
  }
}

function* getReboxBrandboxTotalWasteExcel() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const wasteType: OptionType[] = yield select(selectWasteType);
  const selectedWasteType: OptionType = yield select(selectSelectedWasteType);

  const data: GetOverviewReboxBrandboxType = convertDataOverviewReboxBrandbox(
    dateSettings,
    selectedWasteManagementType,
    wasteType,
    selectedWasteType,
  );

  const api = yield select(selectApi);

  log.debug('get rebox brandbox total weight excel with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      REBOX_BRANDBOX_TOTAL_WASTE_EXCEL,
      false,
      ResponseType.BLOB,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const url = blobToUrl(response.data, '');
      yield put(actions.reboxBrandboxTotalWasteExcelLoaded(url));
    }
  } catch (err: any) {
    yield put(actions.reboxBrandboxTotalWasteExcelError(err));
  }
}

function* getReboxBrandboxWeightPerTypeExcel() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );

  const data: GetOverviewReboxBrandboxType = convertDataOverviewReboxBrandbox(
    dateSettings,
    selectedWasteManagementType,
  );

  const api = yield select(selectApi);

  log.debug('get rebox brandbox weight per type excel with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      REBOX_BRANDBOX_WEIGHT_PER_TYPE_EXCEL,
      false,
      ResponseType.BLOB,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const url = blobToUrl(response.data, '');
      yield put(actions.reboxBrandboxWeightPerTypeExcelLoaded(url));
    }
  } catch (err: any) {
    yield put(actions.reboxBrandboxWeightPerTypeExcelError(err));
  }
}

function* getReboxBrandboxTotalTransactionExcel() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );

  const data: GetOverviewReboxBrandboxType = convertDataOverviewReboxBrandbox(
    dateSettings,
    selectedWasteManagementType,
  );

  const api = yield select(selectApi);

  log.debug('get rebox brandbox total transaction excel with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      REBOX_BRANDBOX_TOTAL_TRANSACTION_EXCEL,
      false,
      ResponseType.BLOB,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const url = blobToUrl(response.data, '');
      yield put(actions.reboxBrandboxTotalTransactionExcelLoaded(url));
    }
  } catch (err: any) {
    yield put(actions.reboxBrandboxTotalTransactionExcelError(err));
  }
}

function* getReboxBrandboxTotalUserExcel() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );

  const data: GetOverviewReboxBrandboxType = convertDataOverviewReboxBrandbox(
    dateSettings,
    selectedWasteManagementType,
  );

  const api = yield select(selectApi);

  log.debug('get rebox brandbox total user excel with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      REBOX_BRANDBOX_TOTAL_USER_EXCEL,
      false,
      ResponseType.BLOB,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const url = blobToUrl(response.data, '');
      yield put(actions.reboxBrandboxTotalUserExcelLoaded(url));
    }
  } catch (err: any) {
    yield put(actions.reboxBrandboxTotalUserExcelError(err));
  }
}

// Drop-in
function* getDashboardDropInOverview() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;

  const data: GetOverviewType = convertDataOverview(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
    // drop point id intentionally made into separate
    // param form selected waste management type to make function reusable
    selectedWasteManagementType?.dropPointId,
  );

  const api = yield select(selectApi);

  log.debug('get dashboard drop-in overview with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      DROP_IN_OVERVIEW,
      false,
      undefined,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      yield put(actions.dropInOverviewLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.dropInOverviewError(err));
  }
}

function* getDropInWeight() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const wasteType: OptionType[] = yield select(selectWasteType);
  const selectedWasteType: OptionType = yield select(selectSelectedWasteType);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;

  const data = convertGetDataParameter(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
    wasteType,
    selectedWasteType,
    undefined,
    selectedWasteManagementType?.dropPointId,
  );

  const api = yield select(selectApi);

  log.debug('get drop in weight with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      DROP_IN_WEIGHT,
      false,
      undefined,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      yield put(actions.dropInWeightLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.dropInWeightError(err));
  }
}

function* getDropInTotalDrop() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;

  const data: GetOverviewType = convertDataOverview(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
    selectedWasteManagementType?.dropPointId,
  );

  const api = yield select(selectApi);

  log.debug('get drop-in total drop with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      DROP_IN_TOTAL_DROP,
      false,
      undefined,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      yield put(actions.dropInTotalDropLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.dropInTotalDropError(err));
  }
}

function* getDropInTotalUser() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;

  const data: GetOverviewType = convertDataOverview(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
    selectedWasteManagementType?.dropPointId,
  );

  const api = yield select(selectApi);

  log.debug('get drop-in user drop with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      DROP_IN_TOTAL_USER,
      false,
      undefined,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      yield put(actions.dropInTotalUserLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.dropInTotalUserError(err));
  }
}

function* getDropInCarbonFootprint() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;

  const data: GetOverviewType = convertDataOverview(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
    selectedWasteManagementType?.dropPointId,
  );

  const api = yield select(selectApi);

  log.debug('get drop-in carbon saving with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      DROP_IN_CARBON_FOOTPRINT,
      false,
      undefined,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      yield put(actions.dropInCarbonFootprintLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.dropInCarbonFootprintError(err));
  }
}

function* getDropInWeightPerType() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;

  const data: GetOverviewType = convertDataOverview(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
    selectedWasteManagementType?.dropPointId,
  );

  const api = yield select(selectApi);

  log.debug('get drop-in weight per type with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      DROP_IN_WEIGHT_PER_TYPE,
      false,
      undefined,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      yield put(actions.dropInWeightPerTypeLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.dropInWeightPerTypeError(err));
  }
}

function* getDropInWasteJourney() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;

  const data: GetOverviewType = convertDataOverview(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
    selectedWasteManagementType?.dropPointId,
  );

  const api = yield select(selectApi);

  log.debug('get dashboard drop in waste journey with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      DROP_IN_WASTE_JOURNEY,
      false,
      undefined,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const data = response.data;
      yield put(actions.dropInWasteJourneyLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.dropInWasteJourneyError(err));
  }
}

function* getDropInWeightExcel() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const wasteType: OptionType[] = yield select(selectWasteType);
  const selectedWasteType: OptionType = yield select(selectSelectedWasteType);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;
  const fileType: string = yield select(selectFileType);

  const data = convertGetDataParameter(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
    wasteType,
    selectedWasteType,
    fileType,
    selectedWasteManagementType?.dropPointId,
  );

  const api = yield select(selectApi);

  log.debug('get drop in weight excel with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      DROP_IN_WEIGHT_EXCEL,
      false,
      ResponseType.BLOB,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const url = blobToUrl(response.data, '');
      yield put(actions.dropInWeightExcelLoaded(url));
    }
  } catch (err: any) {
    yield put(actions.dropInWeightExcelError(err));
  }
}

function* getDropInWasteExcel() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;
  const fileType: string = yield select(selectFileType);

  const data = convertGetDataParameter(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
    undefined,
    undefined,
    fileType,
    selectedWasteManagementType?.dropPointId,
  );

  const api = yield select(selectApi);

  log.debug('get drop in waste excel with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      DROP_IN_WASTE_EXCEL,
      false,
      ResponseType.BLOB,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const url = blobToUrl(response.data, '');
      yield put(actions.dropInWasteExcelLoaded(url));
    }
  } catch (err: any) {
    yield put(actions.dropInWasteExcelError(err));
  }
}

function* getDropInUserExcel() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;
  const fileType: string = yield select(selectFileType);

  const data = convertGetDataParameter(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
    undefined,
    undefined,
    fileType,
    selectedWasteManagementType?.dropPointId,
  );

  const api = yield select(selectApi);

  log.debug('get drop in user excel with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      DROP_IN_USER_EXCEL,
      false,
      ResponseType.BLOB,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const url = blobToUrl(response.data, '');
      yield put(actions.dropInUserExcelLoaded(url));
    }
  } catch (err: any) {
    yield put(actions.dropInUserExcelError(err));
  }
}

function* getDropInWeightPerTypeExcel() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;
  const fileType: string = yield select(selectFileType);

  const data = convertGetDataParameter(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
    undefined,
    undefined,
    fileType,
    selectedWasteManagementType?.dropPointId,
  );

  const api = yield select(selectApi);

  log.debug('get drop in weight per type excel with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      DROP_IN_WEIGHT_PER_TYPE_EXCEL,
      false,
      ResponseType.BLOB,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const url = blobToUrl(response.data, '');
      yield put(actions.dropInWeightPerTypeExcelLoaded(url));
    }
  } catch (err: any) {
    yield put(actions.dropInWeightPerTypeExcelError(err));
  }
}

function* getDropInWasteJourneyExcel() {
  const dateSettings: DateSettingsType = yield select(selectDateSettings);
  const selectedWasteManagementType: WasteManagementType = yield select(
    selectWasteManagementSelected,
  );
  const selectedSubAccount: string = yield select(selectSelectedSubAccount);
  const userDetail: UserDetailType =
    JSON.parse(localStorage.getItem(USER_DETAIL_KEY) ?? '') ??
    defaultUserDetail;
  const fileType: string = yield select(selectFileType);

  const data = convertGetDataParameter(
    dateSettings,
    selectedWasteManagementType,
    selectedSubAccount,
    userDetail,
    undefined,
    undefined,
    fileType,
    selectedWasteManagementType?.dropPointId,
  );

  const api = yield select(selectApi);

  log.debug('get drop in waste journey excel with: ', data);

  try {
    const response = yield call(
      [api, 'generalApiCall'],
      ApiMethod.POST,
      DROP_IN_WASTE_JOURNEY_EXCEL,
      false,
      ResponseType.BLOB,
      undefined,
      data,
    );

    if (response.kind === ApiResponseKind.OK) {
      const url = blobToUrl(response.data, '');
      yield put(actions.dropInWasteJourneyExcelLoaded(url));
    }
  } catch (err: any) {
    yield put(actions.dropInWasteJourneyExcelError(err));
  }
}

export function* dashboardSaga() {
  yield takeLatest(actions.loadWasteType.type, getWasteType);
  yield takeLatest(
    actions.loadBusinessOverview.type,
    getDashboardBusinessOverview,
  );
  yield takeLatest(
    actions.loadBusinessWeightPerPickup.type,
    getBusinessWeightPerPickup,
  );
  yield takeLatest(
    actions.loadBusinessWeightPerType.type,
    getBusinessWeightPerType,
  );
  yield takeLatest(
    actions.loadBusinessExcelWeightPerPickup.type,
    getBusinessExcelWeightPerPickup,
  );
  yield takeLatest(
    actions.loadBusinessExcelWeightPerType.type,
    getBusinessExcelWeightPerType,
  );
  yield takeLatest(
    actions.loadBusinessExcelWasteJourney.type,
    getBusinessExcelWasteJourney,
  );
  yield takeLatest(
    actions.loadBusinessCarbonFootprint.type,
    getBusinessCarbonFootprint,
  );
  yield takeLatest(
    actions.loadBusinessWasteJourney.type,
    getBusinessWasteJourney,
  );

  // Rebox - Brandbox
  yield takeLatest(
    actions.loadReboxBrandboxOverview.type,
    getDashboardReboxBrandboxOverview,
  );
  yield takeLatest(
    actions.loadReboxBrandboxTotalWaste.type,
    getReboxBrandboxTotalWaste,
  );
  yield takeLatest(
    actions.loadReboxBrandboxTotalTransaction.type,
    getDashboardReboxBrandboxTotalTransaction,
  );
  yield takeLatest(
    actions.loadReboxBrandboxTotalUser.type,
    getDashboardReboxBrandboxTotalUser,
  );
  yield takeLatest(
    actions.loadReboxBrandboxWeightPerType.type,
    getDashboardReboxBrandboxWeightPerType,
  );
  yield takeLatest(
    actions.loadReboxBrandboxTotalWasteExcel.type,
    getReboxBrandboxTotalWasteExcel,
  );
  yield takeLatest(
    actions.loadReboxBrandboxWeightPerTypeExcel.type,
    getReboxBrandboxWeightPerTypeExcel,
  );
  yield takeLatest(
    actions.loadReboxBrandboxTotalTransactionExcel.type,
    getReboxBrandboxTotalTransactionExcel,
  );
  yield takeLatest(
    actions.loadReboxBrandboxTotalUserExcel.type,
    getReboxBrandboxTotalUserExcel,
  );

  // Drop-In
  yield takeLatest(actions.loadDropInOverview.type, getDashboardDropInOverview);
  yield takeLatest(actions.loadDropInWeight.type, getDropInWeight);
  yield takeLatest(actions.loadDropInTotalDrop.type, getDropInTotalDrop);
  yield takeLatest(actions.loadDropInTotalUser.type, getDropInTotalUser);
  yield takeLatest(
    actions.loadDropInCarbonFootprint.type,
    getDropInCarbonFootprint,
  );
  yield takeLatest(
    actions.loadDropInWeightPerType.type,
    getDropInWeightPerType,
  );
  yield takeLatest(actions.loadDropInWasteJourney.type, getDropInWasteJourney);
  yield takeLatest(actions.loadDropInWeightExcel.type, getDropInWeightExcel);
  yield takeLatest(actions.loadDropInWasteExcel.type, getDropInWasteExcel);
  yield takeLatest(actions.loadDropInUserExcel.type, getDropInUserExcel);
  yield takeLatest(
    actions.loadDropInWeightPerTypeExcel.type,
    getDropInWeightPerTypeExcel,
  );
  yield takeLatest(
    actions.loadDropInWasteJourneyExcel.type,
    getDropInWasteJourneyExcel,
  );
}
