import * as React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { GlobalStyle } from '../styles/global-styles';
import { NotFoundPage } from './pages/NotFoundPage';
import { useTranslation } from 'react-i18next';
import { Login } from './pages/Auth';
import useToken from '../hooks/useToken';
import { useApiSlice } from '../api/slice';
import { useDispatch } from 'react-redux';
import { useAuthSlice } from './modules/profile/slice';
import { Report } from './pages/Report/Loadable';
import { Dashboard } from './pages/Dashboard/Loadable';
import { Request, Reset } from './pages/ResetPassword/Loadable';
import { AccountManager, ShareAccess } from './pages/AccountManager/Loadable';
import { Profile } from './pages/Profile/Loadable';
import {
  ACCOUNT_MANAGER_PERMITTED_ROLE,
  DASHBOARD_PERMITTED_ROLE,
  REPORT_PERMITTED_ROLE,
} from '../config/global.config';
import { UserDetailType } from '../types/User';
import { USER_DETAIL_KEY } from '../api/api.types';

export function App() {
  const { i18n } = useTranslation();
  const { clearToken, token, refreshToken } = useToken();

  const { actionApi } = useApiSlice();
  const { actions } = useAuthSlice();
  const dispatch = useDispatch();

  // Handle get role
  const getRole = () => {
    if (token) {
      const data: UserDetailType = JSON.parse(
        localStorage.getItem(USER_DETAIL_KEY) ?? '',
      );
      return data?.role;
    }
  };

  useEffect(() => {
    dispatch(actionApi.loadToken());
    if (token) dispatch(actions.loadUserDetail());
  }, [token]);

  useEffect(() => {
    if (refreshToken) {
      dispatch(actions.setRefreshTokenData(refreshToken));
      dispatch(actions.loadRefreshToken());
    }
  }, [refreshToken]);

  // Handle route by role
  const RoleRoute = ({ roles, ...props }: any) => {
    return !roles.length || roles.includes(getRole()) ? (
      <Route {...props} />
    ) : (
      <Redirect to="/" />
    );
  };

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Dashboard Rekosistem"
        defaultTitle="Dashboard Rekosistem"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Rekosistem" />
      </Helmet>

      <Switch>
        {/* navigation reverted to this file */}
        {/*<MainRouter />*/}
        <Route
          exact
          path={process.env.PUBLIC_URL + '/login'}
          component={() => (token ? <Redirect to="/" /> : <Login />)}
        />

        <Route
          exact
          path={process.env.PUBLIC_URL + '/forgot-password'}
          component={() => (token ? <Redirect to="/" /> : <Request />)}
        />

        <Route
          exact
          path={process.env.PUBLIC_URL + '/reset-password/:token'}
          component={() => (token ? <Redirect to="/" /> : <Reset />)}
        />

        <Route
          exact
          path={process.env.PUBLIC_URL + '/logout'}
          component={() => {
            clearToken();
            window.location.replace('/login');
            return <></>;
          }}
        />

        {token ? (
          <Switch>
            <RoleRoute
              roles={DASHBOARD_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/'}
              component={() => <Dashboard title={'Overview'} />}
            />
            <RoleRoute
              roles={DASHBOARD_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/company/:prefix'}
              component={() => <Dashboard title={'Overview'} />}
            />
            {/* Dashboard Route */}
            <RoleRoute
              roles={DASHBOARD_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/dashboard'}
              component={() => <Dashboard title={'Overview'} />}
            />
            {/* Report Route */}
            <RoleRoute
              roles={REPORT_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/report'}
              component={() => <Report title={'Monthly Report'} />}
            />
            {/* Account Manager Route */}
            <RoleRoute
              roles={ACCOUNT_MANAGER_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/account-manager'}
              component={() => <AccountManager title={'Account Manager'} />}
            />
            <RoleRoute
              roles={ACCOUNT_MANAGER_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/share-access'}
              component={() => <ShareAccess title={'Share Access'} />}
            />
            <RoleRoute
              roles={ACCOUNT_MANAGER_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/edit-share-access/:id'}
              component={() => <ShareAccess title={'Share Access'} />}
            />
            {/* Profile Route */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/profile'}
              component={() => <Profile title={'Profile'} />}
            />
            {/*Log Out Route*/}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/logout'}
              component={() => {
                clearToken();
                window.location.replace('/login');
                return <></>;
              }}
            />
            <Route path={'*'} component={() => <NotFoundPage />} />
          </Switch>
        ) : (
          <Redirect to="/login" />
        )}
      </Switch>
      <GlobalStyle />
    </BrowserRouter>
  );
}
