import { format, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';
import moment from 'moment';
import { DateSettingsType } from '../app/modules/profile/slice/types';

const locale = enUS;

export function checkDate(date: Date | string | undefined | null): Date {
  if (!date) {
    return new Date();
  }

  if (typeof date === 'string') {
    return parseISO(date as string);
  }

  return date as Date;
}

/**
 * Return Date ===> 20/02/2022
 */
export function formatDMY(str) {
  return format(checkDate(str), 'dd/MM/yyyy', { locale });
}

/**
 * Return Date ===> 2022
 */
export function formatYearString(str) {
  return format(checkDate(str), 'yyyy', { locale });
}

/**
 * Return Date ===> 20 february 2022
 */
export function formatDate(str) {
  return format(checkDate(str), 'dd MMMM yyyy', { locale });
}

/**
 * Return Date ===> february 2022
 */
export function formatMonth(str) {
  return format(checkDate(str), 'MMMM yyyy', { locale });
}

/**
 * Return Date ===> Week 1 2022
 */
export function formatWeek(str) {
  return 'Week ' + moment(str).isoWeek() + ' ' + formatYearString(str);
}

/**
 * Return Date ===> 20 february 2022 - 23 february 2022
 */
export function formatDateRange(str1, str2) {
  const res1: string = formatDate(str1);
  const res2: string = formatDate(str2);
  if (res1 === res2) return res1;
  else return res1 + ' - ' + res2;
}

/**
 * Return Date ===> february 2022 - march 2022
 */
export function formatMonthRange(str1, str2) {
  const res1: string = formatMonth(str1);
  const res2: string = formatMonth(str2);
  if (res1 === res2) return res1;
  else return res1 + ' - ' + res2;
}

/**
 * Return Date ===> Week 1 2022 - Week 2 2022
 */
export function formatWeekRange(str1, str2) {
  const res1: string = formatWeek(str1);
  const res2: string = formatWeek(str2);
  if (res1 === res2) return res1;
  else return res1 + ' - ' + res2;
}

/**
 * Return Date ===> 20-02-2022 12:52:02
 */
export function formatDateWithHourWithoutSeconds(str) {
  return format(checkDate(str), 'dd-MM-yyyy HH:mm', { locale });
}

/**
 * Return Date ===> 20-02-2022
 */
export function formatDateForm(date: Date | string) {
  return format(checkDate(date), 'dd-MM-yyyy', { locale });
}

export function formatDashboardDisplayDate(dateSettings: DateSettingsType) {
  if (dateSettings.type === 'all-time') {
    return 'Until ' + formatDate(dateSettings.endDate);
  } else if (dateSettings.type === 'range-date') {
    return formatDateRange(dateSettings.startDate, dateSettings.endDate);
  } else if (dateSettings.type === 'month') {
    return formatMonthRange(dateSettings.startDate, dateSettings.endDate);
  } else {
    return formatWeekRange(dateSettings.startDate, dateSettings.endDate);
  }
}

/**
 * Return Date ===> "YYYY-MM-ddTHH:mm:ssZ"
 * 2023-02-13T00:00:00Z
 */
export function formatDashboardPostDate(str: string) {
  return format(checkDate(str), 'yyyy-MM-dd', { locale }) + 'T00:00:00Z';
}

/**
 * Return Month & Year ===> "MMMM yyyy"
 */
export function formatMonthYear(str: string) {
  return format(checkDate(str), 'MMM yyyy', { locale });
}
