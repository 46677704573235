import {
  CarbonFootprintType,
  DashboardTabType,
  DateOptionType,
  OverviewItemType,
} from '../types/Dashboard';
import { images } from '../assets/images';
import { OptionType } from '../app/components/Dropdown/floatingDropdown';
import { BranchLocationType, ShareAccessFormType } from '../types/Account';

export const DATE_NULL = '0001-01-01T00:00:00Z';
export const DATE_SELECTOR_MIN: string = '01-01-2021';

export const DEFAULT_LOADING_TEXT: string = 'Please wait...';

export const DATE_OPTION_ALL: DateOptionType = {
  key: 'all-time',
  string: 'All time',
};

export const DATE_OPTION_DATE_RANGE: DateOptionType = {
  key: 'range-date',
  string: 'Date range',
};

export const DATE_OPTION_MONTH_RANGE: DateOptionType = {
  key: 'month',
  string: 'Month range',
};

export const DATE_OPTION_WEEK_RANGE: DateOptionType = {
  key: 'week',
  string: 'Week range',
};

export const DATE_OPTION: DateOptionType[] = [
  DATE_OPTION_ALL,
  DATE_OPTION_DATE_RANGE,
  DATE_OPTION_MONTH_RANGE,
  DATE_OPTION_WEEK_RANGE,
];

export const DATE_OPTION_MONTH = 2;
export const DATE_OPTION_WEEK = 3;

export const DATE_OPTION_MONTH_KEY = 'month';
export const DATE_OPTION_WEEK_KEY = 'week';
export const DATE_OPTION_ISO_WEEK_KEY = 'isoWeek';
export const CALENDAR_START_SUN: number = 1;

// Management Type
export const BUSINESS_TYPE = 1;
export const DROP_IN_TYPE = 2;
export const REBOX_TYPE = 3;
export const BRANDBOX_TYPE = 4;

export const DASHBOARD_BUSINESS = 'business';
export const DASHBOARD_DROP_IN = 'drop-in';
export const DASHBOARD_REBOX_BRANDBOX = 'rebox-brandbox';

export const ALL_SUB_ACCOUNT = 'all';

// Role
export const OWNER_ROLE_ID: string = 'Owner';
export const HEAD_ROLE_ID: string = 'Head';
export const SUB_ACCOUNT_ROLE_ID: string = 'Sub';
export const DASHBOARD_PERMITTED_ROLE: string[] = [
  OWNER_ROLE_ID,
  HEAD_ROLE_ID,
  SUB_ACCOUNT_ROLE_ID,
];
export const REPORT_PERMITTED_ROLE: string[] = [
  OWNER_ROLE_ID,
  HEAD_ROLE_ID,
  SUB_ACCOUNT_ROLE_ID,
];
export const ACCOUNT_MANAGER_PERMITTED_ROLE: string[] = [
  OWNER_ROLE_ID,
  HEAD_ROLE_ID,
];

export const TAB_BUSINESS: DashboardTabType[] = [
  {
    key: 'weightPerPickupBusiness',
    name: 'Weight per Pick up',
  },
  {
    key: 'weightPerTypeBusiness',
    name: 'Weight per Type',
  },
  {
    key: 'wasteJourneyBusiness',
    name: 'Waste Journey',
  },
  {
    key: 'carbonFootprintBusiness',
    name: 'Carbon Saving',
  },
];

export const TAB_DROP_IN: DashboardTabType[] = [
  {
    key: 'totalWeightDropIn',
    name: 'Total of Weight',
  },
  {
    key: 'totalDrop',
    name: 'Total of Waste Drop',
  },
  {
    key: 'totalUsersDropIn',
    name: 'Total of Users',
  },
  {
    key: 'weightPerTypeDropIn',
    name: 'Weight per Type',
  },
  {
    key: 'wasteJourneyDropIn',
    name: 'Waste Journey',
  },
  {
    key: 'carbonFootprintDropIn',
    name: 'Carbon Saving',
  },
];

export const TAB_REBOX_BRANDBOX: DashboardTabType[] = [
  {
    key: 'totalWasteReboxBrandbox',
    name: 'Total Amount of Waste',
  },
  {
    key: 'weightPerTypeReboxBrandbox',
    name: 'Weight per Type',
  },
  {
    key: 'totalTransactionReboxBrandbox',
    name: 'Total of Transactions',
  },
  {
    key: 'totalUsersReboxBrandbox',
    name: 'Total of Users',
  },
];

// Business
export const businessTotalWeight: OverviewItemType = {
  key: 'totalWeight',
  name: 'Total Weight',
  icon: images.weightIcon,
  units: 'Kilogram',
  fixed: false,
};

export const businessTotalPickup: OverviewItemType = {
  key: 'totalPickup',
  name: 'Total Pick up',
  icon: images.truckIcon,
  units: 'Times',
  fixed: true,
};

export const businessAvgWeight: OverviewItemType = {
  key: 'averageWeight',
  name: 'Avg. Weight per Pick up',
  icon: images.weightIcon,
  units: 'Kilogram/Pick up',
  fixed: false,
};

export const businessRecoveryWeight: OverviewItemType = {
  key: 'recoveryRate',
  name: 'Recovery Rate',
  icon: images.recycleIcon,
  units: 'Percentage',
  fixed: false,
  isPercentage: true,
  tooltipId: 'business-recovery-tooltip',
  tooltipTitle: 'Details',
  tooltipContent:
    'Recovery rate is determined by comparing waste that is successfully prevented from ended up in landfill to the total amount of waste managed regularly by Rekosistem for the client.',
};

export const businessCarbonSaving: OverviewItemType = {
  key: 'totalCarbonSaving',
  name: 'Total Carbon Saving',
  icon: images.carbonIcon,
  units: 'Kg CO₂',
  fixed: false,
  tooltipId: 'business-carbon-tooltip',
  tooltipTitle: 'Details',
  tooltipLink:
    'Turner, D. A., Williams, I. D., & Kemp, S. "Resources, Conservation and Recycling". United Kingdom. 2015.',
  tooltipContent:
    'Total carbon savings represent the kilograms of carbon saved and prevented from contributing to a carbon footprint through the effective waste management provided by Rekosistem, including the replacement of virgin materials with recycled materials in the industry and prevention of illegal waste burning',
};

export const overviewItemBusiness: OverviewItemType[] = [
  businessTotalWeight,
  businessTotalPickup,
  businessAvgWeight,
  businessRecoveryWeight,
  businessCarbonSaving,
];

// Drop in
export const dropInTotalWeight: OverviewItemType = {
  key: 'totalWeight',
  name: 'Total Weight',
  icon: images.weightIcon,
  units: 'Kilogram',
  fixed: false,
};

export const dropInTotalWasteDrop: OverviewItemType = {
  key: 'totalWasteDrop',
  name: 'Total waste drop',
  icon: images.dropInIcon,
  units: 'Times',
  fixed: true,
};

export const dropInAvgDropIn: OverviewItemType = {
  key: 'averageDropIn',
  name: 'Avg. Drop-in',
  icon: images.dropInIcon,
  units: 'Drop-in/',
  fixed: false,
};

export const dropInAvgWeightPerDropIn: OverviewItemType = {
  key: 'averageWeightPerDropIn',
  name: 'Avg. Weight per Drop-in',
  icon: images.weightIcon,
  units: 'Kilogram/Drop-in',
  fixed: false,
};

export const dropInTotalUser: OverviewItemType = {
  key: 'totalUser',
  name: 'Total Users',
  icon: images.userIcon,
  units: 'Users',
  fixed: true,
};

export const dropInAvgUser: OverviewItemType = {
  key: 'averageUser',
  name: 'Avg. Users',
  icon: images.userIcon,
  units: 'Users/',
  fixed: false,
};

export const dropInRecoveryRate: OverviewItemType = {
  key: 'recoveryRate',
  name: 'Recovery Rate',
  icon: images.recycleIcon,
  units: 'Percentage',
  fixed: false,
  isPercentage: true,
  tooltipId: 'drop-in-recovery-tooltip',
  tooltipTitle: 'Details',
  tooltipContent:
    'Recovery rate is determined by comparing waste that is successfully prevented from ended up in landfill to the total amount of waste managed regularly by Rekosistem for the client.',
};

export const dropInTotalCarbonSaving: OverviewItemType = {
  key: 'carbonSaving',
  name: 'Total Carbon Saving',
  icon: images.carbonIcon,
  units: 'Kg CO²',
  fixed: false,
  tooltipId: 'drop-in-carbon-tooltip',
  tooltipTitle: 'Details',
  tooltipLink:
    'Turner, D. A., Williams, I. D., & Kemp, S. "Resources, Conservation and Recycling". United Kingdom. 2015.',
  tooltipContent:
    'Total carbon savings represent the kilograms of carbon saved and prevented from contributing to a carbon footprint through the effective waste management provided by Rekosistem, including the replacement of virgin materials with recycled materials in the industry and prevention of illegal waste burning',
};

export const overviewItemDropIn: OverviewItemType[] = [
  dropInTotalWeight,
  dropInTotalWasteDrop,
  dropInAvgDropIn,
  dropInAvgWeightPerDropIn,
  dropInTotalUser,
  dropInAvgUser,
  dropInRecoveryRate,
  dropInTotalCarbonSaving,
];

// Rebox - Brandbox
export const reboxTotalWasteDrop: OverviewItemType = {
  key: 'totalWasteDrop',
  name: 'Total Waste drop',
  icon: images.dropInIcon,
  units: 'Pcs',
  fixed: true,
};

export const reboxTotalTransaction: OverviewItemType = {
  key: 'totalTransaction',
  name: 'Total Transactions',
  icon: images.transactionIcon,
  units: 'Transactions',
  fixed: true,
};

export const reboxTotalUser: OverviewItemType = {
  key: 'totalUser',
  name: 'Total Users',
  icon: images.userIcon,
  units: 'Users',
  fixed: true,
};

export const reboxAvgWastePerDrop: OverviewItemType = {
  key: 'averageWastePerDrop',
  name: 'Avg. Waste per Drop',
  icon: images.dropInIcon,
  units: 'Pcs/Drop',
  fixed: false,
};

export const reboxAvgUser: OverviewItemType = {
  key: 'averageUser',
  name: 'Avg. Users',
  icon: images.userIcon,
  units: 'Users/',
  fixed: false,
  isRoundUp: true,
};

export const overviewItemReboxBrandbox: OverviewItemType[] = [
  reboxTotalWasteDrop,
  reboxTotalTransaction,
  reboxTotalUser,
  reboxAvgWastePerDrop,
  reboxAvgUser,
];

export const downloadOptionXLS: OptionType = {
  optionText: '.Xls',
  key: 'xls',
  icon: images.xlsIcon,
};

export const downloadOptionCSV: OptionType = {
  optionText: '.Csv',
  key: 'csv',
  icon: images.csvIcon,
};

export const downloadOptionJPEG: OptionType = {
  optionText: '.Jpeg',
  key: 'jpeg',
  icon: images.jpegIcon,
};

export const downloadOption: OptionType[] = [
  downloadOptionXLS,
  downloadOptionCSV,
  downloadOptionJPEG,
];

export const downloadOptionWithoutExcel: OptionType[] = [downloadOptionJPEG];

export const cfTreeSeed: CarbonFootprintType = {
  key: 'treeSeed',
  title: ' planted every 10 years',
  highlight: 'tree seeds',
  background: 'bg-bgCarbon1 bg-cover',
};

export const cfTravelDistance: CarbonFootprintType = {
  key: 'travelDistance',
  title: ' of road trip by car',
  highlight: 'km',
  background: 'bg-bgCarbon2 bg-cover',
};

export const cfCoalBurned: CarbonFootprintType = {
  key: 'coalBurned',
  title: ' of coal being spared',
  highlight: 'kg',
  background: 'bg-bgCarbon3 bg-cover',
};

export const cfGasoline: CarbonFootprintType = {
  key: 'gasoline',
  title: ' of gasoline',
  highlight: 'liters',
  background: 'bg-bgCarbon4 bg-cover',
};

export const cfRecycledWaste: CarbonFootprintType = {
  key: 'recycledWaste',
  title: ' of waste recycled',
  highlight: 'trash bags',
  background: 'bg-bgCarbon5 bg-cover',
};

export const carbonFootprint: CarbonFootprintType[] = [
  cfTreeSeed,
  cfTravelDistance,
  cfCoalBurned,
  cfGasoline,
  cfRecycledWaste,
];

export const CARBON_FOOTPRINT_DECIMAL_NUM: number = 2;

export const DATE_WEEK_TYPE: string = 'week';
export const DATE_MONTH_TYPE: string = 'month';

export const CONTACT =
  'https://wa.me/+6282130004070?text=Halo%20Admin%20Rekosistem,%20saya%20mau%20tanya%20mengenai%20Dashboard%20Repickup%20Bisnis';
export const COMPANY_WEB = 'https://rekosistem.com/';

export const SANKEY_BLURRED_LINK_OPACITY: number = 0.3;
export const SANKEY_FOCUSED_LINK_OPACITY: number = 0.6;
export const SANKEY_HEIGHT: number = 1500;
export const SANKEY_NODE_PADDING: number = 20;

export const DEFAULT_PAGINATION_ROWS_PER_PAGE_OPTIONS: number[] = [
  10, 20, 30, 40, 50, 100, 200, 300, 400, 500,
];

export const REPORT_TIMEOUT: number = 60000;

// Account Manager
export const ACCOUNT_STATUS_LIST = [
  {
    value: 1,
    label: 'Active',
  },
  {
    value: 0,
    label: 'Suspended',
  },
];
export const OVERVIEW_HEAD_ACCOUNT: string = 'Total head account managed';
export const HEAD_ACCOUNT_UNIT: string = 'Head account';
export const OVERVIEW_SUB_ACCOUNT: string = 'Total sub-account managed';
export const SUB_ACCOUNT_UNIT: string = 'Sub account';
export const OVERVIEW_ACCESS_SHARED: string = 'Total access shared';
export const SHARE_UNIT: string = 'Access shared';

// Share access
export const SHARE_ACCESS_ROLE_HEAD: OptionType = {
  key: 'Head',
  optionText: 'Head Account',
  descText:
    'The head account is the account that leads the sub account and can see all branch locations',
};
export const SHARE_ACCESS_ROLE_SUB: OptionType = {
  key: 'Sub',
  optionText: 'Sub Account',
  descText:
    'Sub account is account that are under the head account and can only see certain branch locations',
};
export const SHARE_ACCESS_ROLE_OPTION: OptionType[] = [
  SHARE_ACCESS_ROLE_HEAD,
  SHARE_ACCESS_ROLE_SUB,
];

export const SHARE_ACCESS_BRANCH_LOCATION_STATUS_OPTION: OptionType[] = [
  {
    key: 'active',
    optionText: 'Active',
    id: 1,
  },
  {
    key: 'suspended',
    optionText: 'Suspended',
    id: 0,
  },
];

export const BRANCH_LOCATION_DEFAULT: BranchLocationType = {
  key: '',
  optionText: '',
  status: SHARE_ACCESS_BRANCH_LOCATION_STATUS_OPTION[0],
  alias: '',
};

export const SHARE_ACCESS_FORM_DEFAULT: ShareAccessFormType = {
  accountName: '',
  email: '',
  role: SHARE_ACCESS_ROLE_OPTION[0].key,
  branchLocation: [BRANCH_LOCATION_DEFAULT],
};

export const NOT_FOUND_INDEX: number = -1;
export const FIRST_INDEX: number = 0;
export const LENGTH_0: number = 0;
export const LENGTH_1: number = 1;
export enum CONTENT_TYPE {
  PDF = 'application/pdf',
}
